import React from 'react';
import { HiArrowNarrowLeft, HiArrowNarrowRight } from 'react-icons/hi';

function ProjectFooter({ prev, prevLink, next, nextLink }) {
  return (
    <footer className='project-footer'>
      <a className='prev' href={prevLink}>
        <HiArrowNarrowLeft />
        <div className='prev-detail'>
          <p>PREV PROJECT </p>
          <p>{prev}</p>
        </div>
      </a>
      <a className='next' href={nextLink}>
        <div className='next-detail'>
          <p>NEXT PROJECT</p>
          <p>{next}</p>
        </div>
        <HiArrowNarrowRight />
      </a>
    </footer>
  );
}

export default ProjectFooter;
