import React from "react";

function ProjectLayout({ children, templete }) {
  return (
    <>
      <div className={["template", templete].join(" ")}>
        <div className="info-frame">{children}</div>
      </div>
    </>
  );
}

export default ProjectLayout;
