import React from 'react';
import ProjectInfo from '../../components/ProjectInfo';
import projectPic from '../../components/images/project_frontlineAirsoft.png';
import TopButton from '../../components/TopButton';
import ProjectLayout from '../../components/Layout/ProjectLayout';
import PartingLine from '../../components/PartingLine';
import img1 from '../../components/images/frontline-10-moodboard.png';
import img2 from '../../components/images/frontline-11-map.png';
import img3 from '../../components/images/frontline-12-team.png';
import img4 from '../../components/images/frontline-13-game.png';
import img5 from '../../components/images/frontline-14-shopping.png';
import img6 from '../../components/images/frontline-15-cart.png';
import img7 from '../../components/images/frontline-2-seo.png';
import img8 from '../../components/images/frontline-3-googleSeo.png';
import img9 from '../../components/images/frontline-4-keyword.png';
import img10 from '../../components/images/frontline-5-persona.png';
import img11 from '../../components/images/frontline-7-flowChart.jpg';
import img12 from '../../components/images/frontline-8-uiFlow.png';
import img13 from '../../components/images/frontline-9-wireframe.png';
import ProjectFooter from '../../components/Layout/ProjectFooter';

function ProjectFA() {
  const detailInfo = {
    title: 'FRONTLINE AIRSOFT',
    type: 'E-commerce WEB',
    describe:
      'This project aimed to design a brand new one-stop platform dedicated to the airsoft community',
    role: 'UI / UX Engineer',
    response: [
      'UI/UX Design,',
      'Web App Development,',
      'Custom CSS, Animation',
    ],
  };
  return (
    <div className='page-frame fa'>
      <TopButton />
      <ProjectInfo detailInfo={detailInfo} />
      <div className='block-two'>
        <img src={projectPic} alt='' />
      </div>
      <div className='template-frame'>
        <ProjectLayout templete='one start'>
          <p className='tag golden'>BACKGROUND</p>
          <h1>Overview</h1>
          <p>
            FRONTLINE AIRSOFT was a brand new one-stop platform for the airsoft
            community. Our client wanted a site that brought the players and
            business owners together and attracted more people to the world of
            airsoft in Taiwan. The website would provide information and news,
            and it would also be an online store.
          </p>
        </ProjectLayout>
        <ProjectLayout templete='one'>
          <div className='paragrph golden'>
            <p>
              “I want to play airsoft in Taiwan, but I don’t know where I can
              get more information about it.” - some airsoft beginner wrote on
              social media.
            </p>
          </div>
        </ProjectLayout>
        <ProjectLayout templete='one'>
          <h1>Challenges</h1>
          <ul>
            <li>
              The necessary information, news, and knowledge of airsoft is
              scattered all over different websites in Taiwan. It is difficult
              for beginners to have a correct understanding of this sport.
            </li>
            <li>
              There was no one-stop platform for beginners to step into the
              world of airsoft.
            </li>
            <li>
              The airsoft players did not have a platform to connect with each
              other.
            </li>
          </ul>
        </ProjectLayout>
        <ProjectLayout templete='one'>
          <h1>My Role</h1>
          <p>
            As the UI/UX engineer of this project, I built frameworks and
            prototypes to deliver my vision, design principles, and content
            strategy. I{' '}
            <span className='bold golden'>
              put the plan into practice from beginning to end, I made sure to
              get the team on the same page, and I refined the decision making
              process to ensure efficiency.
            </span>{' '}
            I also{' '}
            <span className='bold golden'>
              developed the website interface,
            </span>{' '}
            built the JavaScript interaction and CSS animation.
          </p>
        </ProjectLayout>
        <PartingLine />
        <ProjectLayout templete='one'>
          <p className='tag golden'>DESIGN GOALS</p>
          <h1>01. Design with Disco Vibe</h1>
        </ProjectLayout>
        <ProjectLayout templete='five'>
          <div className='line golden'></div>
          <p>
            Utilizing the bright neon colors to bring a lighter and fun disco
            vibe to the military themed website.
          </p>
        </ProjectLayout>
        <ProjectLayout templete='one'>
          <img src={img1} alt='' />
        </ProjectLayout>
        <ProjectLayout templete='one'>
          <h1>02. Vivid Visual</h1>
        </ProjectLayout>
        <ProjectLayout templete='five'>
          <div className='line golden'></div>
          <p>
            Using a real world and top-down street view to demonstrate airsoft
            games, venues, and all necessary information.
          </p>
        </ProjectLayout>
        <ProjectLayout templete='one'>
          <img src={img2} alt='' />
        </ProjectLayout>
        <ProjectLayout templete='one'>
          <h1>03. Build-a-Team Feature</h1>
          <p>
            Providing a player platform to build teams and connect with each
            other online and offline.
          </p>
        </ProjectLayout>
        <ProjectLayout templete='five'>
          <div className='line golden'></div>
          <p>
            I created the shield shaped layout with 2D dynamic effects to
            display team information and provide a fun user experience.
          </p>
        </ProjectLayout>
        <ProjectLayout templete='one'>
          <img src={img3} alt='' />
        </ProjectLayout>
        <ProjectLayout templete='one'>
          <h1>04. Dynamic Event Page Design</h1>
          <p>
            Displaying the event page with waterfall side effects and
            highlighting the call-to-action buttons for customized events with
            neon colors.
          </p>
        </ProjectLayout>
        <ProjectLayout templete='five'>
          <div className='line golden'></div>
          <p>
            The event video will be provided after every event or game. Members
            can also check their own game records, team ranking, and other
            information through the member page.
          </p>
        </ProjectLayout>
        <ProjectLayout templete='one'>
          <img src={img4} alt='' />
        </ProjectLayout>
        <ProjectLayout templete='one'>
          <h1>05. “Themed” Product Categorization</h1>
        </ProjectLayout>
        <ProjectLayout templete='five'>
          <div className='line golden'></div>
          <p>
            Creating product categories with different themes, like private
            security, so players can buy what they want through an intuitive and
            fun way.
          </p>
        </ProjectLayout>
        <ProjectLayout templete='one'>
          <img src={img5} alt='' />
        </ProjectLayout>
        <ProjectLayout templete='one'>
          <h1>06. Virtual Try-on Feature</h1>
        </ProjectLayout>
        <ProjectLayout templete='five'>
          <div className='line golden'></div>
          <p>
            Applying Virtual Try-on features in the shopping cart so players can
            have a clear view of their shopping list and also know what product
            to find next.
          </p>
        </ProjectLayout>
        <ProjectLayout templete='one'>
          <img src={img6} alt='' />
        </ProjectLayout>
        <ProjectLayout templete='one'>
          <h1>Business goals</h1>
          <p>
            A platform that is dedicated to bring the airsoft community
            together. It is an e-commerce platform but also provides information
            on events, games, and local business. This platform will provide a
            more reliable place for players to buy equipment, and help existing
            players to connect and attract beginners to step into the world of
            airsoft.
          </p>
        </ProjectLayout>
        <PartingLine />
        <ProjectLayout templete='one'>
          <p className='tag golden'>PROCESS</p>
          <h1>Research - SEO Analysis</h1>
          <p>Naming the website</p>
          <p>
            During the naming process, we used{' '}
            <span className='bold golden'>
              awoo, Google Analytics, Similar Web, and other analytics tools{' '}
            </span>
            to determine the name of the website.
          </p>
        </ProjectLayout>
        <ProjectLayout templete='five'>
          <div className='line golden'></div>
          <p>"FRONTLINE AIRSOFT" was chosen as the name of the project.</p>
        </ProjectLayout>
        <ProjectLayout templete='one'>
          <img src={img7} alt='' />
        </ProjectLayout>
        <ProjectLayout templete='one'>
          <img src={img8} alt='' />
        </ProjectLayout>
        <ProjectLayout templete='one'>
          <img src={img9} alt='' />
        </ProjectLayout>
        <ProjectLayout templete='one'>
          <h1>Meet the users</h1>
          <img src={img10} alt='' />
          <p>
            After conducting user interviews and research, the 3 target groups
            were chosen:
          </p>
          <br></br>
          <p>
            {' '}
            <span className='bold'>Target group A:</span>
          </p>
          <p>
            New players. They need not only the guidelines of the game but also
            the basic knowledge of Airsoft.
          </p>
          <p>
            <span className='bold'>Target group B:</span>
          </p>
          <p>
            Advanced players. They want a community to connect with other
            players, build their own teams, and host events.
          </p>
          <p>
            <span className='bold'>Target group C:</span>
          </p>
          <p>
            Professional players. They also want a community to connect and some
            additional services such as equipment trading platforms.
          </p>
        </ProjectLayout>
        <PartingLine />
        <ProjectLayout templete='one'>
          <p className='tag golden'>Solutions</p>
          <h1>Flowchart</h1>
          <img src={img11} alt='' />
        </ProjectLayout>
        <ProjectLayout templete='one'>
          <h1>UI flow</h1>
          <img src={img12} alt='' />
        </ProjectLayout>
        <ProjectLayout templete='one'>
          <h1>Wireframe</h1>
          <img src={img13} alt='' />
        </ProjectLayout>
        <PartingLine />
        <ProjectLayout templete='one end'>
          <p className='tag golden'>Conclusion</p>
          <h1>Results</h1>
          <p>
            This website brought the Airsoft platform in Taiwan to the next
            level:
          </p>
          <ol>
            <li>
              Players from different regions could connect online, without
              geography limitations.
            </li>
            <li>
              Beginners could step into the world of airsoft and access the
              event's information easier.
            </li>
            <li>
              The airsoft community in Taiwan had a one-stop platform with all
              the information about the sport.
            </li>
            <li>
              A wide range of features could attract new players while retaining
              existing ones.
            </li>
            <br />
          </ol>
          <p>
            In conclusion, our client was pleased with this web design and would
            like to advance and improve it in the future. Hopefully, with this
            platform dedicated to airsoft, there will be more players joining
            this sport.
          </p>
        </ProjectLayout>
      </div>
      <ProjectFooter
        prev='SCSB'
        prevLink='/project_shanghai_bank'
        next='STRATEGY GO'
        nextLink='/project_xslego_stock'
      />
    </div>
  );
}

export default ProjectFA;
