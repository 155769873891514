import React from 'react';
import ProjectInfo from '../../components/ProjectInfo';
import projectPic from '../../components/images/xslego-1-cover.png';
import TopButton from '../../components/TopButton';
import ProjectLayout from '../../components/Layout/ProjectLayout';
import PartingLine from '../../components/PartingLine';
import img1 from '../../components/images/xslego-3-define.png';
import img2 from '../../components/images/xslego-4-wireframe.png';
import img3 from '../../components/images/xslego-5-prototype.png';
import img4 from '../../components/images/xslego-6-designSystem.png';
import img5 from '../../components/images/xslego-2-cover.png';
import img6 from '../../components/images/xslego-7-strategy.png';
import img7 from '../../components/images/xslego-8-editor.png';
import ProjectFooter from '../../components/Layout/ProjectFooter';

function ProjectXslego() {
  const detailInfo = {
    title: 'Strategy GO Platform',
    type: 'Stock Strategy Investing Platform',
    describe:
      'This project aimed to design a strategy investing platform specifically for stocks to make investing faster and easier.',
    role: 'UI / UX Designer',
    response: ['UI/UX Design,', 'Research,', 'Cross-platform design'],
  };
  return (
    <div className='page-frame xslego'>
      <TopButton />
      <ProjectInfo detailInfo={detailInfo} />
      <div className='block-two'>
        <img src={projectPic} alt='' />
      </div>
      <div className='template-frame'>
        <ProjectLayout templete='one start'>
          <p className='tag blue'>BACKGROUND</p>
          <h1>Overview</h1>
          <p>
            Strategy GO is a reliable trading platform where customers can
            confidently invest in stock. There are many customized features,
            users can follow and subscribe to preferred strategies that are
            built by other investors, and create the best investment strategies
            based on their preferences. It’s very simple and intuitive. Whether
            the user is a beginner or an expert in investing, Strategy GO makes
            trading straightforward, automatic, and time-saving.
          </p>
        </ProjectLayout>
        <ProjectLayout templete='one'>
          <div className='paragrph blue'>
            <p>
              I only had a small amount of money. So I want to pick the best
              stocks and make profits.
            </p>
          </div>
        </ProjectLayout>
        <ProjectLayout templete='one'>
          <h1>Challenges</h1>
          <ol>
            <li>
              Stock trading becomes more popular among Generation Z and
              millennials. Although the current product offers customized
              investment strategies, it still requires users to learn
              programming before creating the strategy. It is not flexible and
              agile, so we need a new method that only allows users to build
              strategies but also attracts more B2C clients.
            </li>
            <li>
              Many professional investors use coding to develop their
              strategies, but it’s too difficult for beginners. Even provided
              with a strategy program, these users do not know how to fine-tune
              parameters based on their own needs.
            </li>
            <li>
              The existing software has not been updated, and the interface has
              made it even more difficult for beginners to use, so users spend
              more time familiarizing themselves with the tools before they
              begin investing.
            </li>
            <li>
              This is my first time working on a trading platform for stocks. So
              I will need to comprehend customer requirements, product features,
              and other competitive app user behaviors in a short time.
            </li>
          </ol>
        </ProjectLayout>
        <ProjectLayout templete='one'>
          <h1>My Role</h1>
          <p>
            As part of the design team, I was responsible for UI/UX and
            collaborated with the project manager to deliver all major designs.
            Our mission was to design the Strategy GO web platform LEGO and
            allow users to develop automated stock strategies on a single
            platform.
          </p>
        </ProjectLayout>
        <ProjectLayout templete='one'>
          <p className='tag blue'>Define</p>
          <h1>Design Goals</h1>
          <ul>
            <li>
              To create a system that is similar to playing with LEGO. Each
              block represents an investment condition, so users can build their
              own strategies by adding blocks.
            </li>
            <li>
              To allow users without programming skills to develop customized
              strategies and then validate them through the platform. Users can
              choose whether or not to invest automatically.
            </li>
            <li>
              To provide useful strategies for stock beginners. They can follow
              and subscribe to the strategies that were built by other expert
              investors they preferred.
            </li>
            <li>
              To create a clean and simple user interface by reframing the
              information architecture and functions, as well as creating
              consistency in UI and UX flows.
            </li>
          </ul>
        </ProjectLayout>
        <PartingLine />
        <ProjectLayout templete='one'>
          <p className='tag blue'>PROCESS</p>
          <h1>Discover</h1>
          <ol>
            <li>
              Kick-off Meeting — I collaborated closely with the project
              management team. Based on product specifications, we determined
              the feature, the goals, and the business requirements for this
              project. We also reviewed the existing products and listed the
              unique selling point for each product.
            </li>
            <li>
              Stakeholder Interviews — Through stakeholder interviews, we
              grasped the advanced information architecture, the insights and
              requirements of expert users, and also had an understanding of
              investing.
            </li>
            <li>
              Competitive Analysis — Reviewing and analyzing competitive
              products helped us to understand what are the popular features and
              how they design their interface.
            </li>
          </ol>
        </ProjectLayout>
        <ProjectLayout templete='one'>
          <h1>Value Proposition Canvas</h1>
          <img src={img1} alt='' />
          <p>
            We created a “Value Proposition Canvas” to ensure that the product
            is positioned around users’ requirements and benefits. Through this
            process, we determined which features have the highest value and
            implemented them in the first stage.
            <br />
            <br />
            We decided to implement 4 functions in the first stage, including{' '}
            <span className='bold blue'>
              Strategies Markets, Strategy Editor, Monitoring, and Back-testing.
            </span>{' '}
          </p>
        </ProjectLayout>
        <PartingLine />
        <ProjectLayout templete='one'>
          <p className='tag blue'>Solutions</p>
          <h1>Wireframe</h1>
          <img src={img2} alt='' />
          <ul>
            <li>
              We used Miro to create wireframes at the beginning. It helped us
              communicate in the cross-functional team effectively and speed up
              the process of drawing Wireframe and ensuring each function is
              built correctly.
            </li>
          </ul>
        </ProjectLayout>
        <ProjectLayout templete='one'>
          <p className='tag blue'>Solutions</p>
          <h1>Prototype</h1>
          <img src={img3} alt='' />
          <ul>
            <li>
              The prototype was created through Figma which provides a function
              to demonstrate the interactive flows of the website. It was used
              for the cross-functional team to have effective communication and
              helped the engineering team to develop simultaneously.
            </li>
          </ul>
        </ProjectLayout>
        <ProjectLayout templete='one'>
          <h1>Design system</h1>
          <img src={img4} alt='' />
        </ProjectLayout>
        <ProjectLayout templete='one'>
          <h1>Micro-interaction design</h1>
          <img className='img-margin' src={img5} alt='' />
          <img className='img-margin' src={img6} alt='' />
          <img src={img7} alt='' />
        </ProjectLayout>
        <PartingLine />
        <ProjectLayout templete='one'>
          <h1>Conclusion</h1>
          <p>
            We created an easy-to-use platform for users to track top stock
            trading strategies and also build their customized investing
            strategies without having any programming skills.
          </p>
        </ProjectLayout>
        <ProjectLayout templete='five'>
          <div className='line blue'></div>
          <p>
            Whether the user is a beginner or an expert, the new platform makes
            creating customized strategies and automatic investment accessible.
            Trading is now simple, automatic, and time-saving.
          </p>
        </ProjectLayout>
        <ProjectLayout templete='one end'>
          <h1>Ｗhat I have learned</h1>
          <ul>
            <li>
              Using the prototype to communicate in a cross-functional team can
              help to speed up the discussion, clarify requirements, and build
              MVP for each feature.
            </li>
            <li>
              When analyzing and creating the information architecture under the
              current software structure, it is important to prioritize each
              feature according to the value of what information and functions
              it provides.
            </li>
            <li>
              Choosing interviewees from diverse backgrounds reduced bias in
              data collection.
            </li>
            <li>
              Consulting and testing prototypes with clients can be very useful
              because they can give feedback immediately.
            </li>
          </ul>
        </ProjectLayout>
      </div>
      <ProjectFooter
        prev='FRONTLINE'
        prevLink='/project_frontline_airsoft'
        next='CTBC'
        nextLink='/project_ctbc_bank'
      />
    </div>
  );
}

export default ProjectXslego;
