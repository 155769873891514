import React from 'react';
import ProjectInfo from '../../components/ProjectInfo';
// import projectPic from "../../components/images/shanghai-3-cover.png";
// import projectPicBG from "../../components/images/shanghai-2-bk.png";
import TopButton from '../../components/TopButton';
import ProjectLayout from '../../components/Layout/ProjectLayout';
import PartingLine from '../../components/PartingLine';
import img1 from '../../components/images/shanghai-4-wireframe.png';
import img2 from '../../components/images/shanghai-9-highlight.png';
import img6 from '../../components/images/shanghai-10-highlight.png';
import img7 from '../../components/images/shanghai-11-highlight.png';
import img3 from '../../components/images/shanghai-0-prototype.gif';
import img4 from '../../components/images/shanghai-7-cssAni.gif';
import img5 from '../../components/images/shanghai-8-cssAni.gif';
import ProjectFooter from '../../components/Layout/ProjectFooter';

function ProjectShanghai() {
  const detailInfo = {
    title: 'SCSB Personal Banking App',
    type: 'Mobile Banking APP',
    describe:
      'The goal of this project was to design the 2.0 version of the SCSB personal mobile banking App.',
    role: 'UI / UX Engineer',
    response: ['UI/UX Design,', 'Web App Development,', 'Interaction Design'],
    path: 'https://play.google.com/store/apps/details?id=tw.com.scsb.android.mbank&hl=en_US&gl=US',
  };
  return (
    <div className='page-frame scsb'>
      <TopButton />
      <ProjectInfo detailInfo={detailInfo} />
      <div className='block-two'>
        <div className='img-frame'>
          {/* <img className="main-img" src={projectPic} alt="" />
          <img className="bg-img" src={projectPicBG} alt="" /> */}
        </div>
      </div>
      <div className='template-frame'>
        <ProjectLayout templete='one start'>
          <p className='tag red'>BACKGROUND</p>
          <h1>Overview</h1>
          <p>
            Generation Z and Millennials are growing up in a world with cutting
            edge technology and smart devices. This makes these generations
            access various services through smartphones. However, most of the
            traditional banks in Taiwan have a hard time keeping up with the
            younger generations- with some banking services only being available
            in-branch and not remotely or online. For these customers, online
            banking is a better choice because it can save time and avoid
            inefficient workflows. The goal of this project was to build a new
            personal banking app for SCSB to be one step further in technology
            innovation.
          </p>
        </ProjectLayout>
        <ProjectLayout templete='one'>
          <div className='paragrph red'>
            <p>
              “Young generations depend on smartphones for everything so they
              are more likely to choose the bank that offers most services
              online. How does SCSB go digital and attract these young clients?”
            </p>
          </div>
        </ProjectLayout>
        <ProjectLayout templete='one'>
          <h1>Challenges</h1>
          <ol>
            <li>
              A more modern design: the UX flow and information architecture of
              the original design did not fit with Generation Z’s app behavior;
              thus, it required reevaluating the entire structure.
            </li>
            <li>
              All the banking services are intertwined, which necessitates
              creating a better user interface by consulting with different
              functional departments and understanding their needs.
            </li>
            <li>
              Discovering the best solution based on the data SCSB provided and
              stakeholders’ needs.
            </li>
            <li>
              The design is significantly influenced by the bank's original
              system architecture.
            </li>
          </ol>
        </ProjectLayout>
        <ProjectLayout templete='one'>
          <h1>My Role</h1>
          <p>
            As a part of a design team and the team leader, my responsibility
            included creating the project timeline, conducting primary UX
            research, and working on UI/UX design based on research results. And
            as a UI/UX engineer, I was in charge of developing the app interface
            and building a{' '}
            <span className='bold red'>reusable component library </span> to
            improve scalability for potential growth.
          </p>
        </ProjectLayout>
        <PartingLine />
        <ProjectLayout templete='one'>
          <p className='tag red'>DESIGN GOALS</p>
          <h1>Design Goals</h1>
          <ul>
            <li>
              To design a brand new visual identity that has a cutting-edge
              aesthetic for SCSB.
            </li>
            <li>
              To build a personal banking app that meets business objectives
              while aligning with the new visual identity.
            </li>
            <li>
              To give SCSB a friendly, energetic, and welcoming image with a new
              visual identity.
            </li>
            <li>
              To improve design consistency and usability by making elements
              uniform.
            </li>
            <li>To Add micro-interaction to increase customer stickiness.</li>
          </ul>
        </ProjectLayout>
        <PartingLine />
        <ProjectLayout templete='one'>
          <p className='tag red'>Process</p>
          <h1>Research</h1>
          <ol>
            <li>
              Identify the most important features through the data collected
              from the users.
            </li>
            <li>
              Gain insight into competitive products by conducting competitor
              analysis.
            </li>
            <li>
              Consult with stakeholders to understand their demands, among which
              their desire to focus on the investment in the app.
            </li>
          </ol>
        </ProjectLayout>
        <ProjectLayout templete='five'>
          <div className='line red'></div>
          <p>
            Based on the results of the research, the first stage of the
            solution was to draw attention to providing easy access to personal
            accounts, money transfers, credit cards and investments information,
            and exchange rates.
          </p>
        </ProjectLayout>
        <ProjectLayout templete='one'>
          <h1>Wireframe & Prototypes</h1>
          <img src={img1} alt='' />
          <ul>
            <li>Discuss with clients using wireframe and prototype.</li>
          </ul>
        </ProjectLayout>
        <ProjectLayout templete='one'>
          <h1>Design Process</h1>
          <img className='design-img' src={img2} alt='' />
          <img className='design-img' src={img6} alt='' />
          <img className='design-img' src={img7} alt='' />
          <div className='gif-frame'>
            <img className='phone-gif1' src={img3} alt='' />
          </div>
        </ProjectLayout>
        <ProjectLayout templete='one'>
          <h1>Micro-interaction design</h1>
          <div className='gif-frame'>
            <img className='phone-gif2' src={img4} alt='' />
            <img className='phone-gif3' src={img5} alt='' />
          </div>
          <p>
            Micro-interaction design can guide the users, give visual feedback
            and build an engaging and welcoming image. During the process, I
            have tried various CSS-animations to find the best one.
          </p>
        </ProjectLayout>
        <PartingLine />
        <ProjectLayout templete='one'>
          <h1>Conclusion</h1>
          <p>
            When discussing this project with the client, I have used a
            comprehensive solution, including all the wireframes, layouts, and
            prototypes. This helped my team and the client to come to a common
            ground quickly during the meeting.
          </p>
        </ProjectLayout>
        <ProjectLayout templete='five'>
          <div className='line red'></div>
          <p>
            Since we are living in the digital era, designing a product for
            users to make investments based on their behavior is a good start
            for taking the business digital. And nowadays, the biggest obstacle
            to the mobile banking app design is constrained by the bank’s system
            architecture. My solution was to bring in people from different
            functional teams to develop new technologies to create an entirely
            new feature. In order to launch the app punctually, we also proposed
            moving new feature development to the second stage.
          </p>
        </ProjectLayout>
        <ProjectLayout templete='one end'>
          <h1>Ｗhat I have learned</h1>
          <ul>
            <li>
              For design and development, I have found out that development can
              be sped up if the two design systems are synced. So the engineers
              can build functions simultaneously.
            </li>
            <li>
              Bank clients are detail-oriented. When cooperating with these
              clients, it is important to use actual data during every mockup.
            </li>
            <li>
              It is crucial to test every prototype with clients, so as to get
              feedback and improve the design immediately.
            </li>
          </ul>
        </ProjectLayout>
      </div>
      <ProjectFooter
        prev='CTBC'
        prevLink='/project_ctbc_bank'
        next='FRONTLINE'
        nextLink='/project_frontline_airsoft'
      />
    </div>
  );
}

export default ProjectShanghai;
