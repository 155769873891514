import React, { useEffect } from 'react';
import headImg from './images/home_mainpic.svg';
import Button from './Button';
import baffle from 'baffle';

function ProjectInfo(props) {
  useEffect(() => {
    let b = baffle('.title').start();
    b.set({
      characters: '░▓░░█▒░█░▓░░█▒░█░▓░░█▒░█',
      speed: 100,
    });
    b.reveal(3000);
  }, []);
  // console.log("proInfo", props);

  return (
    <div className='project-info'>
      <div className='header'>
        <img src={headImg} alt='' />
        {/* <div className="inverted-1">
          <a href="/">
            Back to projects <span>←</span>
          </a>
          <span></span>
        </div> */}
        <div className='backBtn'>
          <a href='/'>
            Back to projects <span>←</span>
          </a>
        </div>
      </div>
      <div className='main-block'>
        <div className='main-frame'>
          <h1 className='title'>{props.detailInfo.title}</h1>
          <p className='type'>{props.detailInfo.type}</p>
          <p className='detail'>{props.detailInfo.describe}</p>
          <div className='line'></div>
          <div className='bottom'>
            <div className='left'>
              <div className='role'>
                <h3>Role</h3>
                <p>{props.detailInfo.role}</p>
              </div>
              <div className='respon'>
                <h3>Responsibility</h3>
                {props.detailInfo.response.map((item, index) => (
                  <p key={index}>{item}</p>
                ))}
              </div>
            </div>
            {props.detailInfo.path && (
              <Button text='VISIT PLATFORM' path={props.detailInfo.path} />
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default ProjectInfo;
