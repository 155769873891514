import React from 'react';
// import Layout from "./components/Layout/Layout";
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Home from './views/Home';
import ProjectCTBC from './views/projects/ProjectCTBC';
import ProjectShanghai from './views/projects/ProjectShanghai';
import ProjectFA from './views/projects/ProjectFA';
import ProjectXslego from './views/projects/ProjectXslego';
import ProjectTCB from './views/projects/ProjectTCB';
import ProjectCoffeeTalk from './views/projects/ProjectCoffeeTalk';
// import { Helmet } from 'react-helmet';

function App() {
  return (
    <>
      {/* <Helmet>
      <title>MENG-YUN LEE</title>
      <meta name='description' content='Meng Yun lee portfolio' />
      </Helmet> */}
      <Router>
        {/* <Layout> */}
        <Routes>
          <Route exact path='/' element={<Home />} />
          <Route exact path='/project_ctbc_bank' element={<ProjectCTBC />} />
          <Route
            exact
            path='/project_shanghai_bank'
            element={<ProjectShanghai />}
          />
          <Route
            exact
            path='/project_frontline_airsoft'
            element={<ProjectFA />}
          />
          <Route
            exact
            path='/project_xslego_stock'
            element={<ProjectXslego />}
          />
          <Route exact path='/project_tcb_bank' element={<ProjectTCB />} />
          <Route
            exact
            path='/project_coffeeTalk_web'
            element={<ProjectCoffeeTalk />}
          />
        </Routes>
        {/* </Layout> */}
      </Router>
    </>
  );
}

export default App;
