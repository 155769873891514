import React from 'react';
import resume from '../../components/images/MengYunLee_cv.pdf';

function HomeFooter() {
  return (
    <footer className='home-footer'>
      <div className='top'>
        <h3>
          Get in touch
          <span role='img' aria-label='work'>
            👩🏻‍💻
          </span>
        </h3>
        <div className='link-frame'>
          <a
            href='https://www.linkedin.com/in/mengyunlee-017/'
            target='_blank'
            rel='noopener noreferrer'
          >
            <h3>Linkedin</h3>
          </a>

          <h3>|</h3>

          <a className='btn' href={resume} download>
            <h3> Resume</h3>
          </a>
        </div>
      </div>
      <h5>MENGYUNLEE © ALL RIGHTS RESERVED</h5>
    </footer>
  );
}

export default HomeFooter;
