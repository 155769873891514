import React, { useState, useRef } from 'react';
import HomeLeft from '../components/HomeLeft';
import btnBg from './../components/images/btn-check-2x.png';
import DelayedLink from '../components/tool/DelayedLink';
import HomeFooter from '../components/Layout/HomeFooter';
import iconPS from '../components/images/icon_ps.svg';
import iconAi from '../components/images/icon_illu.svg';
import iconXD from '../components/images/icon_XD.svg';
import iconFigma from '../components/images/icon_figma.svg';
import iconHtml from '../components/images/icon_html.svg';
import iconCss from '../components/images/icon_css.svg';
import iconJS from '../components/images/icon_js.svg';
import iconReact from '../components/images/icon_react.svg';
import iconGithub from '../components/images/icon_github.svg';
import ScrollTop from '../components/ScrollTop';
import TopButton from '../components/TopButton';

function Home() {
  const [openPage, setOpenPage] = useState(false);
  const [isVisible, setIsVisible] = useState(false);
  const [project, setProjectColor] = useState('');
  const titleRef = useRef();

  const openPageFunc = () => {
    setTimeout(() => setRedirectNow(true), 1000);
    setOpenPage(true);
  };
  const [redirectNow, setRedirectNow] = useState(false);
  function handleBackClick() {
    titleRef.current.scrollIntoView({ behavior: 'smooth' });
  }
  const handleScroll = (event) => {
    // console.log("scrollTop: ", event.currentTarget.scrollTop);
    if (event.currentTarget.scrollTop > 140) {
      setIsVisible(true);
    } else {
      setIsVisible(false);
    }
  };
  // console.log('project', project);
  // console.log('project', redirectNow);
  return (
    <div className='wrapper'>
      <div className='left'>
        <TopButton />
        <HomeLeft />
        <div className='right mobile' onScroll={handleScroll}>
          {isVisible && <ScrollTop handleBackClick={handleBackClick} />}
          {/* <div className={[openPage ? "open" : "", "page ctbc"].join(" ")}></div> */}
          <div
            className={[openPage ? 'active' : '', 'left-layer'].join(' ')}
            // style={{
            //   backgroundColor: (() => {
            //     switch (project) {
            //       case "one":
            //         return "#138b81";
            //       case "two":
            //         return "#2cb8bf";
            //       case "three":
            //         return "#1b1c1c";
            //       case "four":
            //         return "#95b7ff";
            //       case "five":
            //         return "#62dfc6";
            //       default:
            //         return "white";
            //     }
            //   })(),
            // }}
          ></div>
          <div
            className={[
              openPage ? 'active' : '',
              'left-layer left-layer--2',
            ].join(' ')}
          ></div>
          <div
            className={[
              openPage ? 'active' : '',
              'left-layer left-layer--3',
            ].join(' ')}
          ></div>
          <div
            className={[
              openPage ? 'active' : '',
              'left-layer left-layer--4',
            ].join(' ')}
          ></div>
          <div
            className={[
              openPage ? 'active' : '',
              'left-layer left-layer--4',
            ].join(' ')}
          ></div>
          {/* <div
          className={[
            openPage ? "active" : "",
            "left-layer left-layer--5",
          ].join(" ")}
        ></div> */}
          <div className='about' ref={titleRef}>
            <div className='bg-block'>
              <div className='about-info'>
                <p>
                  Experienced UI/UX Engineer with 5-year professional
                  experience. Equipped with proficiency in HTML5, CSS3,
                  JavaScript, React, SASS, and Bootstrap. Open to learning new
                  web technologies and languages.
                </p>
                <p>
                  Qualified within a professional background in design with a
                  wide range of skills in UI/UX design, web design, interactive
                  design, and graphic design. Always ready to keep the user in
                  mind and strive to create the best experience beyond devices
                  and products.
                </p>
                <div className=''>
                  <h4>Design</h4>
                  <div className='icon-frame'>
                    <img src={iconFigma} alt='' />
                    <img src={iconPS} alt='' />
                    <img src={iconAi} alt='' />
                    <img src={iconXD} alt='' />
                  </div>
                </div>
                <div className=''>
                  <h4>Engineering</h4>
                  <div className='icon-frame'>
                    <img src={iconJS} alt='' />
                    <img src={iconReact} alt='' />
                    <img src={iconHtml} alt='' />
                    <img src={iconCss} alt='' />
                    <img src={iconGithub} alt='' />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className='project five six' id='bottom'>
            <div className='img-frame'></div>
            <div className='info-frame'>
              <div className='title'>
                <h1>CoffeeTalk</h1>
                <h3>Web</h3>
              </div>
              <h4>
                Frontend Engineer
                <span className='tech-tag'>React</span>
                <span className='tech-tag'>SASS</span>
                <span className='tech-tag'>Bootstrap</span>
                <span className='tech-tag'>RESTful API</span>
              </h4>
              <div className='btn-flex-wrap'>
                <DelayedLink
                  delay={800}
                  to='/project_tcb_bank'
                  name='five'
                  setProjectColor={setProjectColor}
                >
                  <button onClick={openPageFunc}>VISIT GITHUB</button>
                  <div className='whiteBg'></div>
                  <img src={btnBg} alt='' />
                </DelayedLink>
                <DelayedLink
                  delay={800}
                  to='/project_coffeeTalk_web'
                  name='five'
                  setProjectColor={setProjectColor}
                >
                  <button onClick={openPageFunc}>VISIT PLATFORM</button>
                  <div className='whiteBg'></div>
                  <img src={btnBg} alt='' />
                </DelayedLink>
              </div>
            </div>
          </div>
          <div className='project one'>
            <div className='img-frame'></div>
            <div className='info-frame white'>
              <div className='title'>
                <h1>CTBC Business Banking</h1>
                <h3>APP</h3>
              </div>
              <h4>
                UI/UX Engineer
                <span className='tech-tag'>UI/UX</span>
                <span className='tech-tag'>HTML</span>
                <span className='tech-tag'>CSS</span>
                <span className='tech-tag'>Bootstrap</span>
                <span className='tech-tag'>jQuery</span>
              </h4>
              <DelayedLink
                delay={700}
                to='/project_ctbc_bank'
                name='one'
                setProjectColor={setProjectColor}
              >
                <div className='whiteBg'></div>
                <button onClick={openPageFunc}>UX Case Study</button>
                <div className='whiteBg'></div>
                <img src={btnBg} alt='' />
              </DelayedLink>
            </div>
          </div>
          <div className='project two'>
            <div className='img-frame'></div>
            <div className='info-frame'>
              <div className='title flex-start'>
                <h1>Shanghai Commercial Bank Personal online Banking</h1>
                <h3>APP</h3>
              </div>
              <h4>
                UI/UX Engineer
                <span className='tech-tag'>UI/UX</span>
                <span className='tech-tag'>HTML</span>
                <span className='tech-tag'>CSS</span>
                <span className='tech-tag'>jQuery</span>
              </h4>
              <DelayedLink
                delay={800}
                to='/project_shanghai_bank'
                name='two'
                setProjectColor={setProjectColor}
              >
                <button onClick={openPageFunc}>UX Case Study</button>
                <div className='whiteBg'></div>
                <img src={btnBg} alt='' />
              </DelayedLink>
            </div>
          </div>
          <div className='project three'>
            <div className='img-frame'></div>
            <div className='info-frame white'>
              <div className='title'>
                <h1>FRONTLINE AIRSOFT</h1>
                <h3>Web</h3>
              </div>
              <h4>
                UI/UX Engineer
                <span className='tech-tag'>UI/UX</span>
                <span className='tech-tag'>HTML</span>
                <span className='tech-tag'>CSS</span>
                <span className='tech-tag'>Bootstrap</span>
                <span className='tech-tag'>jQuery</span>
              </h4>
              <DelayedLink
                delay={800}
                to='/project_frontline_airsoft'
                name='three'
                setProjectColor={setProjectColor}
              >
                <button onClick={openPageFunc}>UX Case Study</button>
                <div className='whiteBg'></div>
                <img src={btnBg} alt='' />
              </DelayedLink>
            </div>
          </div>
          <div className='project four'>
            <div className='img-frame'></div>
            <div className='info-frame white'>
              <div className='title'>
                <h1>STRATEGY GO Stock investing</h1>
                <h3>Web</h3>
              </div>
              <h4>
                UI/UX Engineer
                <span className='tech-tag'>UI/UX</span>
                <span className='tech-tag'>HTML</span>
                <span className='tech-tag'>CSS</span>
              </h4>
              <DelayedLink
                delay={800}
                to='/project_xslego_stock'
                name='four'
                setProjectColor={setProjectColor}
              >
                <button onClick={openPageFunc}>UX Case Study</button>
                <div className='whiteBg'></div>
                <img src={btnBg} alt='' />
              </DelayedLink>
            </div>
          </div>
          {/* <div className='project five' id='bottom'>
            <div className='img-frame'></div>
            <div className='info-frame'>
              <div className='title'>
                <h1>TCB-BANK Digital-account</h1>
                <h3>Web</h3>
              </div>
              <h4>UI/UX Engineer</h4>
              <DelayedLink
                delay={800}
                to='/project_tcb_bank'
                name='five'
                setProjectColor={setProjectColor}
              >
                <button onClick={openPageFunc}>VISIT PLATFORM</button>
                <div className='whiteBg'></div>
                <img src={btnBg} alt='' />
              </DelayedLink>
            </div>
          </div> */}
          <HomeFooter />
        </div>
      </div>
    </div>
  );
}

export default Home;
