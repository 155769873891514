import React from 'react';
import btnBg from './images/btn-check-2x.png';
import resume from '../components/images/MengYunLee_cv.pdf';

function button(props) {
  // console.log("btn props", props);
  return (
    <div className='btn-frame'>
      {props.type === 'download' ? (
        <a className='btn' href={resume} download>
          {props.text}
        </a>
      ) : (
        <a
          className='btn'
          href={props.path}
          target='_blank'
          rel='noopener noreferrer'
        >
          {props.text}
        </a>
      )}
      <div className='whiteBg'></div>
      <img src={btnBg} alt='' />
    </div>
  );
}

export default button;
