import React, { useRef, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
export default function DelayedLink({
  delay,
  replace,
  state,
  to,
  name,
  setProjectColor,
  ...props
}) {
  const navigate = useNavigate();
  const timerRef = useRef();

  useEffect(() => () => clearTimeout(timerRef.current), []);

  const clickHandler = (e) => {
    e.preventDefault();
    // console.log(name);
    setProjectColor(name);
    timerRef.current = setTimeout(navigate, delay, to, { replace, state });
  };
  return <Link to={to} {...props} onClick={clickHandler} />;
}
