import React from 'react';
import img1 from './images/home_bgcomp1.svg';
import img2 from './images/home_bgcomp2.svg';
import img3 from './images/home_bgcomp3.svg';
import img4 from './images/home_bgcomp4.svg';
import img5 from './images/home_bgcomp5.svg';
import img6 from './images/home_bgcomp6.svg';
import mainPic from './images/home_mainpic.svg';
import iconLinkedin from './images/icon_linkedin.svg';
import iconGmail from './images/icon_gmail.svg';
import Button from './Button';
import {
  MouseParallaxChild,
  MouseParallaxContainer,
} from 'react-parallax-mouse';

function HomeLeft() {
  const specialText = '{';
  const specialText2 = '}';
  return (
    <div className='left-frame'>
      {/* <img className="home_bgcomp1" src={img1} alt="" />
      <img className="home_bgcomp2" src={img2} alt="" />
      <img className="home_bgcomp3" src={img3} alt="" />
      <img className="home_bgcomp4" src={img4} alt="" /> */}
      <img className='home_bgcomp5' src={img5} alt='' />
      <img className='home_bgcomp6' src={img6} alt='' />
      <MouseParallaxContainer
        className='parallax'
        containerStyle={{
          width: '100%',
          height: '100%',
          display: 'grid',
          position: 'absolute',
          gridTemplateColumns: 'auto auto auto auto auto',
        }}
        globalFactorX={0.3}
        globalFactorY={0.3}
        resetOnLeave
      >
        <MouseParallaxChild
          className='home_bgcomp1'
          factorX={0.2}
          factorY={0.2}
          style={{
            // filter: "invert(1)",
            // display: "flex",
            // alignItems: "center",
            // justifyContent: "center",
            // width: "50%",
            height: '80%',
            position: 'absolute',
            top: '-5%',
          }}
        >
          <img height='60%' src={img1} alt='' />
        </MouseParallaxChild>
        <MouseParallaxChild
          className='home_bgcomp2'
          factorX={1}
          factorY={0.5}
          style={{
            // filter: "invert(1)",
            // display: "flex",
            // alignItems: "center",
            // justifyContent: "center",
            // width: "auto",
            // height: "100%",
            position: 'absolute',
            top: '40%',
          }}
        >
          <img height='100%' src={img2} alt='' />
        </MouseParallaxChild>
        <MouseParallaxChild
          className='home_bgcomp3'
          factorX={0.3}
          factorY={0.5}
          style={{
            // filter: "invert(1)",
            // display: "flex",
            // alignItems: "center",
            // justifyContent: "center",
            // width: "auto",
            // height: "100%",
            position: 'absolute',
            top: '50%',
          }}
        >
          <img height='100%' src={img3} alt='' />
        </MouseParallaxChild>
        <MouseParallaxChild
          className='home_bgcomp4'
          factorX={0.7}
          factorY={1}
          style={{
            // filter: "invert(1)",
            // display: "flex",
            // alignItems: "center",
            // justifyContent: "center",
            // width: "auto",
            // height: "100%",
            position: 'absolute',
            bottom: '-10%',
            left: '20%',
          }}
        >
          <img width='100%' src={img4} alt='' />
        </MouseParallaxChild>
        {/* <MouseParallaxChild
          className="home_bgcomp5"
          factorX={0.5}
          factorY={0.5}
          style={{
            filter: "invert(1)",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            width: "auto",
            height: "100%",
          }}
        >
          <img height="50%" src={img5} alt="" />
        </MouseParallaxChild> */}
      </MouseParallaxContainer>
      <div className='main-info'>
        <img className='home_mainpic' src={mainPic} alt='' />
        <div className='top'>
          <h1 className='name'>Meng-yun Lee</h1>
          <h3 className='title'>Front-End Engineer | UI/UX Engineer</h3>
          <h2 className='code-info'>{specialText}</h2>
          <h2 className='code-info'>"Design",</h2>
          <h2 className='code-info'>"development",</h2>
          <h2 className='code-info'>"users",</h2>
          <div className='love-frame code-info'>
            <h2 className='code-info'>"Love".</h2>
            {/* <img src={imgCircle} alt="" /> */}
            <div className='arcContain'>
              <div className='archide archideLeft'>
                <div className='arc'></div>
              </div>
              <div className='archide'>
                <div className='arc'></div>
              </div>
            </div>
          </div>
          <h2 className='code-info'>{specialText2}</h2>
        </div>
        <div className='down'>
          <Button text='Download Resume' type='download' />
          <div className='link-frame'>
            <a
              href='https://www.linkedin.com/in/mengyunlee-017/'
              target='_blank'
              rel='noopener noreferrer'
            >
              <img src={iconLinkedin} alt='' />
            </a>
            <a
              href='mailto:leemengyun.eva@gmail.com'
              target='_blank'
              rel='noopener noreferrer'
            >
              <img src={iconGmail} alt='' />
            </a>
          </div>
        </div>
      </div>
    </div>
  );
}

export default HomeLeft;
