import React from 'react';
import ProjectInfo from '../../components/ProjectInfo';
// import projectPicLeft from "../../components/images/CTBC-UX-1-cover-left.png";
// import projectPicRight from "../../components/images/CTBC-UX-1-cover-right.png";
import TopButton from '../../components/TopButton';
import ProjectLayout from '../../components/Layout/ProjectLayout';
import PartingLine from '../../components/PartingLine';
import img1 from '../../components/images/ctbc-3-wireframe.png';
import img2 from '../../components/images/ctbc-4-designSystem.png';
import img3 from '../../components/images/CTBC-UX-1-cover.png';
import img4 from '../../components/images/CTBC-UX-5-designScreen.png';
import img5 from '../../components/images/CTBC-UX-7-hilight-transfer.png';
import ProjectFooter from '../../components/Layout/ProjectFooter';

function ProjectCTBC() {
  const detailInfo = {
    title: 'CTBC Business Banking APP',
    type: 'Business Banking APP',
    describe:
      'The goal of this project was to design the 2.0 version of CTBC Business Banking App.',
    role: 'UI / UX Engineer',
    response: [
      'UI/UX Design,',
      'Web App Development,',
      'Custom CSS, Animation',
    ],
    path: 'https://apps.apple.com/fi/app/%E4%B8%AD%E4%BF%A1%E8%A1%8C%E5%8B%95%E4%BC%81%E6%A5%AD%E5%AE%B6/id1118299749?l=fi&platform=iphone',
  };
  return (
    <div className='page-frame ctbc'>
      <TopButton />
      <ProjectInfo detailInfo={detailInfo} />
      <div className='block-two'>
        <div className='img-frame'>
          {/* <div className="left-img"></div>
          <div className="right-img"></div> */}
          {/* <img className="left-img" src={projectPicLeft} alt="" /> */}
          {/* <img className="right-img" src={projectPicRight} alt="" /> */}
        </div>
      </div>
      <div className='template-frame'>
        <ProjectLayout templete='one start'>
          <p className='tag green'>BACKGROUND</p>
          <h1>Overview</h1>
          <p>
            CTBC Bank is one of the largest privately owned banks in Taiwan.
            With CTBC business app, business users can access all the services
            provided on the Business-Online-Banking website with a high level of
            security. The service includes checking accounts from different
            regions and countries, accessing the bank activity reports,
            processing transactions, and all these services can be operated
            through this app.
          </p>
          <p>For this version, the goals aimed to achieve were:</p>

          <ul>
            <li>
              To become a one-stop banking app that can log in to different bank
              accounts, and check real-time account activity and business
              updates.
            </li>
            <li>
              To operate the authentications and smart transactions, and manage
              the cash flow in the easiest way.
            </li>
            <li>
              To deliver an intuitive user interface to reduce human error. Add
              auto-save function and able to pause and save when creating
              transactions.
            </li>
          </ul>
        </ProjectLayout>
        <ProjectLayout templete='one'>
          <h1>Challenges</h1>
          <p>The design needed to:</p>
          <ul>
            <li>Fit with the existing systems and security policies.</li>
            <li>Further integrate and streamlines transaction workflows</li>
            <li>Provide a user-friendly interface.</li>
            <li>
              Keep local features without conflicting with versions launched in
              different markets, with different region-specific requirements.
            </li>
          </ul>
        </ProjectLayout>
        <ProjectLayout templete='one'>
          <h1>My Role</h1>
          <p>
            As a UI/UX engineer I have discovered a solution meeting{' '}
            <span className='bold green'>
              customer expectations, project feasibility, and technical
              requirements.
            </span>{' '}
            At the same time, I have modularized the UI system for future
            scalability.
          </p>
        </ProjectLayout>
        <PartingLine />
        <ProjectLayout templete='seven'>
          <div className='left'>
            <p className='tag green'>DESIGN GOALS</p>
            <h1>01. Innovative features</h1>
            <p>
              When handling large payments, enterprise users were required to
              fill out a lot of forms varying in the transaction types and
              regions, which was time-consuming.
            </p>
            <div className='line-paragrph'>
              <div className='line green'></div>
              <p>
                As a result, I have divided the forms into smaller components to
                make them more flexible and created different transaction
                interfaces.
              </p>
            </div>
          </div>
          <div className='right'>
            <img src={img5} alt='' />
          </div>
        </ProjectLayout>
        <ProjectLayout templete='one'>
          <h1>02. Easy editing</h1>
          <p>
            Our users were obliged to do repetitive tasks such as editing,
            reviewing, and approving transactions.
          </p>
        </ProjectLayout>
        <ProjectLayout templete='five'>
          <div className='line green'></div>
          <p>
            I have added new features for saving payee information and being
            able to pause and save when creating transactions. This way the
            users can quickly and easily access the information for their
            recurring transactions.
          </p>
        </ProjectLayout>
        <ProjectLayout templete='one'>
          <h1>03. Intuitive display</h1>
          <p>
            With a simple and straightforward workflow, the app has a friendly
            and intuitive interface and is able to to reduce users’ learning
            curve.
          </p>
        </ProjectLayout>
        <ProjectLayout templete='five'>
          <div className='line green'></div>
          <p>
            By adding graphical charts and micro-animations, users were able to
            have a more intuitive, efficient, and enjoyable app experience.
          </p>
        </ProjectLayout>
        <ProjectLayout templete='one'>
          <p className='tag green'>Define</p>
          <h1>Projects goals</h1>
          <ul>
            <li>
              To improve design consistency and usability by making elements
              uniform.
            </li>
            <li>To add micro-interaction to increase customer stickiness.</li>
            <li>
              To add innovative functions to create unique sales points among
              markets.
            </li>
            <li>
              To make the best user experience by rebuilding the information
              architecture and simplifying the transition process.
            </li>
          </ul>
        </ProjectLayout>
        <PartingLine />
        <ProjectLayout templete='one'>
          <p className='tag green'>Process</p>
          <h1>WIREFRAME</h1>
          <img src={img1} alt='' />
          <ul>
            <li>Demonstrating the most important product information.</li>
            <li>
              Being able to pay attention on the details such as
              functionalities, information architecture, user experience,
              workflow, and usability.
            </li>
            <li>Making changes promptly when requirements change. .</li>
            <li>
              Developing effective communication between clients, project
              managers, and engineers.
            </li>
          </ul>
        </ProjectLayout>
        <ProjectLayout templete='one'>
          <h1>Modularity in both UI system and Development system</h1>
          <img src={img2} alt='' />
          <ul>
            <li>
              Allowing the team to collaborate seamlessly and speed up the
              project delivery by using Sketch components.
            </li>
            <li>
              Modularizing a system while enabling the team to identify the
              existing components, adapt to different requirements and support
              various types of scalability.
            </li>
            <li>
              With the change of requirements, modifying or replacing a
              relatively small part rather than overhauling the entire system,
              which helps the team to reduce a heavy workload.
            </li>
          </ul>
        </ProjectLayout>
        <ProjectLayout templete='one'>
          <div className='paragrph green'>
            <p>
              Modularization has recently become popular in software
              development. My modularized UI system provides a clear view to
              identify the existing components in different categories and also
              enables engineers to use each component to develop and improve
              scalability for a potential growth.
            </p>
          </div>
        </ProjectLayout>
        <PartingLine />
        <ProjectLayout templete='one'>
          <p className='tag green'>Conclusion</p>
          <h1>Result</h1>
          <ol>
            <li>
              Provided visualized charts to present information on multiple
              accounts on one page.
            </li>
            <li>
              Improved flexibility of the website by creating a clear
              information architecture, reframing infrastructure, optimizing the
              development process, and modularizing design components.
            </li>
            <li>
              Made UI elements uniform and aligned the design with the brand
              identity. For the apps launched in specific regions, it also
              provided customized features for users.
            </li>
            <li>
              Used micro interaction to provide visual feedback, guide users,
              and improve usability.
            </li>
          </ol>
        </ProjectLayout>
        <ProjectLayout templete='one'>
          <img src={img3} alt='' />
        </ProjectLayout>
        <ProjectLayout templete='one end'>
          <img src={img4} alt='' />
        </ProjectLayout>
      </div>

      <ProjectFooter
        prev='STRATEGY GO'
        prevLink='/project_xslego_stock'
        next='SCSB'
        nextLink='/project_shanghai_bank'
      />
      {/* <ProjectTempate2 text={img12} />
      <ProjectTempate3 image="project_ctbc.png" />
      <ProjectTempate4 image="project_ctbc.png" />
      <ProjectTempate5 />
      <ProjectTempate6 /> */}
    </div>
  );
}

export default ProjectCTBC;
