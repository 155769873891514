import React, { useState } from "react";
import { BsArrowUp } from "react-icons/bs";

function TopButton() {
  const [visible, setVisible] = useState(false);
  const toggleVisible = () => {
    const scrolled = document.documentElement.scrollTop;
    if (scrolled > 300) {
      setVisible(true);
    } else if (scrolled <= 300) {
      setVisible(false);
    }
  };
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
      /* you can also use 'auto' behaviour
         in place of 'smooth' */
    });
  };
  window.addEventListener("scroll", toggleVisible);
  return (
    <div
      className="topBtn-frame"
      onClick={scrollToTop}
      style={{ display: visible ? "inline" : "none" }}
    >
      <div className="topBtn">
        <BsArrowUp />
      </div>
      <div className="topbtn-bg"></div>
    </div>
  );
}

export default TopButton;
