import React, { useEffect } from 'react';

function ProjectCoffeeTalk() {
  useEffect(() => {
    //open new tab for the project
    window.open(
      // "https://actlink.tcb-bank.com.tw/linepay/v1.0.0/digitalDep",
      'https://github.com/leemengyun/twitter-platform-frontend',
      '_blank' // <- This is what makes it open in a new window.
    );
    //let original tag still stay the same block
    window.location.href = '/#bottom';
  });
  return <div className='page-frame ctbc'></div>;
}

export default ProjectCoffeeTalk;
